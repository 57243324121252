<template>
  <div>
    <div class="gfooter__charcount" v-if="maxLen">
      {{ charCount }} of {{ maxLen }} max characters
    </div>
    <div
      :id="descriptionId"
      class="gfooter__description"
      v-if="showDescriptionBelow"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldFooter',
  props: [
    'descriptionId',
    'showDescriptionBelow',
    'maxLen',
    'charCount',
    'description',
  ],
};
</script>

<style scoped lang="scss">
.gfooter {
  &__charcount {
    font-size: 12px;
    margin: 4px 0;
    color: #767676;
  }

  &__description {
    font-weight: 400;
    margin: 8px 0;
    font-size: 12px;
  }
}
</style>
