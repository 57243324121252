import Vue from 'vue';
import VueRouter from 'vue-router';

import Header from '../components/Header.vue';
import HomeBody from '../components/HomeBody.vue';
// const HomeBody = () => import('../components/HomeBody.vue');
import Body from '../components/Body.vue';
import Footer from '../components/Footer.vue';


Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Home',
      path: '/',
      components: {
        header: Header,
        content: HomeBody,
        footer: Footer,
      },
    },
    {
      name: 'Search',
      path: '/search',
      components: {
        header: Header,
        content: () => import('../components/Search.vue'),
        footer: Footer,
      },
    },
    {
      name: 'Landing',
      path: '/:landing',
      components: {
        header: Header,
        content: Body,
        footer: Footer,
      },
    },
    {
      name: 'Inner',
      path: '/:landing/:inner',
      components: {
        header: Header,
        content: Body,
        footer: Footer,
      },
    },
    {
      name: '404',
      path: '/404',
      components: {
        header: Header,
        content: () => import('../components/NotFound.vue'),
        footer: Footer,
      },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
