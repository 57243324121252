<template>
  <div class="gravity-consent">
    <FieldHeader
      :inputId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="false"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <div
      :id="descriptionId"
      v-if="showDescriptionAbove"
      class="gravity-consent__desc"
    >
      {{ fieldData.description }}
    </div>
    <div class="gravity-consent__input-container">
      <input
        class="gravity-consent__input"
        :name="joinSlugs('input', fieldData.id)"
        :id="joinSlugs('choice', fieldData.formId, fieldData.id)"
        type="checkbox"
        :value="fieldData.value"
        :checked="fieldData.isSelected"
        :aria-required="fieldData.isRequired"
        @change="handleInput"
      />
      <label
        class="gravity-consent__label"
        :for="joinSlugs('choice', fieldData.formId, fieldData.id)"
        :id="joinSlugs('label', fieldData.formId, fieldData.id)"
        >{{ fieldData.checkboxLabel }}
      </label>
    </div>
    <div
      :id="descriptionId"
      v-if="showDescriptionBelow"
      class="gravity-consent__desc"
    >
      {{ fieldData.description }}
    </div>
  </div>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
// import FieldFooter from './partials/FieldFooter.vue';

export default {
  name: 'GravityConsent',
  components: { FieldHeader },
  mixins: [GravityForms],
  methods: {
    handleInput(event) {
      this.$emit('input', {
        checked: event.target.checked,
        name: `input_${this.fieldData.id}_1`,
        type: this.fieldData.type,
        value: '1',
      });
      this.$emit('input', {
        checked: event.target.checked,
        name: `input_${this.fieldData.id}_2`,
        type: this.fieldData.type,
        value: this.fieldData.checkboxLabel,
      });
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.gravity-consent {
  &__desc {
    width: 100%;
    max-height: 240px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    margin: 16px 0;
    padding: 6px 8px;
    font-size: .688em;
  }
  &__label {
    margin-left: 8px
  }
}
</style>
