import Vue from 'vue';
import VueHead from 'vue-head';
import VueLazyload from 'vue-lazyload';
import { TweenLite } from 'gsap/TweenLite';
import 'gsap/CSSPlugin';
import * as vClickOutside from 'v-click-outside-x';
import VueGtag from 'vue-gtag'
import router from './router/router';
import App from './App.vue';
import './registerServiceWorker';
import store from './store/store';

Vue.use(vClickOutside);

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = false;
  // Vue.use(VueGtm, {
  //   id: 'GTM-T6LXVFW',
  //   enabled: true,
  //   debug: false,
  //   loadScript: true,
  //   vueRouter: router,
  // });

  // Vue.use(VueAnalytics, {
  //   id: 'UA-20665772-1',
  // });
  if (process.env.VUE_APP_GA4) {
    Vue.use(VueGtag, {
      config: {
        id: process.env.VUE_APP_GA4,
      },
    }, router)
  }
} else {
  Vue.config.productionTip = true;
}

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});

Vue.use(VueHead);

// Check if page exists
router.beforeEach((to, from, next) => {
  // if landing or inner route, check if page exist
  if (to.name === 'Landing' || to.name === 'Inner') {
    // define proceed (to check whether page exists)
    function proceed() {
      function isValid(param) {
        let validity = false;
        const pageList = store.state.landingPage.concat(store.state.page);
        for (let i = 0; i < pageList.length; i += 1) {
          if (Object.prototype.hasOwnProperty.call(pageList[i], 'status')
            && pageList[i].status === 'publish'
            && Object.prototype.hasOwnProperty.call(pageList[i], 'slug')
            && Object.prototype.hasOwnProperty.call(pageList[i], 'type')
            && (
              (pageList[i].type === 'landing_page'
                && !param.inner
                && pageList[i].slug === param.landing
              )
              || (pageList[i].type === 'page'
                && Object.prototype.hasOwnProperty.call(pageList[i], 'parent')
                && pageList[i].parent
                && to.params.landing === pageList[i].parent.slug
                && to.params.inner === pageList[i].slug
              )
            )
          ) {
            validity = true;
            break;
          }
        }
        return validity;
      }
      // if page doesn't exist, redirect to 404
      if (!isValid(to.params)) {
        next({ name: '404' });
      // if valid, continue to page
      } else {
        next();
      }
    }
    // wait for api to load pages
    if (store.state.loadedPages < 2) {
      store.watch(
        (state) => state.loadedPages,
        (value) => {
          // when loaded, proceed
          if (value === 2) {
            proceed();
          }
        },
      );
    // if pages already loaded, proceed
    } else {
      proceed();
    }
  // continue
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
