<template>
  <div id="app">
    <section v-if="checkStatusError()">Error</section>
    <section v-else>
      <LoadingScreen :checkStatusLoaded="checkStatusLoaded"
      :spaPrerenderTrigger="spaPrerenderTrigger()"/>
      <router-view name="header" :apiAcf="apiAcf"/>
      <router-view name="content" :apiAcf="apiAcf" :checkStatusLoaded="checkStatusLoaded"/>
      <router-view name="footer" :apiAcf="apiAcf" v-if="checkStatusLoaded"/>
    </section>

    <!-- Service Worker update pop-up -->
    <template v-if="updateExists">
      <a @click="refreshApp()" class="sw-update-bg">
      </a>
      <div @click="refreshApp()" class="sw-update">
        An update is available, click anywhere to continue.
      </div>
    </template>

  </div>
</template>

<script>

// Mixins
import updateSW from '@/mixins/updateSW';
import pageType from '@/mixins/pageType';

// Components
import LoadingScreen from '@/components/ui-components/LoadingScreen.vue';

export default {
  created() {
    this.$store.dispatch('getApi');
  },
  head: {
    meta: [
      {
        name: 'application-name',
        content: 'University of Illinois Chicago',
      },
      {
        name: 'description',
        content: 'Located in the heart of one of the world’s great cities, the University of Illinois Chicago is a vital part of the educational, technological and cultural fabric of the region. As Chicago’s only public research university with more than 33,000 students, 16 colleges, a hospital and a health sciences system, UIC provides students access to excellence and opportunity.',
        id: 'desc',
      },
      {
        name: 'google-site-verification',
        content: '4olqK8HMATn4OkmXUKEGdcETVPpplQk9Y9I2odvKYgg',
      },
    ],
    script: [
      {
        type: 'text/javascript',
        src: 'https://onetrust.techservices.illinois.edu/scripttemplates/otSDKStub.js',
        sri_fallback: '/onetrust-backup.js',
        integrity: 'sha384-AncUC5I/IiNBJi+i/sN3Mcvm4aBI6BYxI0BwRuUL6xJ4TYY8UwZ+OvtE8cp6NpIS sha384-TTKBhTSYepjvtvNzRAfLjSJ/Dq+PiAXHiQ+5e4mazqq/9Atwmv907iwXt96gOz0d',
        crossorigin: 'anonymous',
        charset: 'UTF-8',
        'data-domain-script': 'c2f2262d-b694-4eba-8f4b-142c102b685a',
        head: true,
        async: true,
      },
      {
        type: 'text/javascript',
        head: true,
        inner: 'function OptanonWrapper() { }',
      },
      {
        type: 'text/javascript',
        src: process.env.VUE_APP_EMERGENCY_ALERT,
        // body: true,
        async: true,
      },
      {
        type: 'text/javascript',
        src: 'https://siteimproveanalytics.com/js/siteanalyze_2950.js',
        body: true,
        async: true,
      },
    ],
    style: [
      { type: 'text/css', inner: '.optanon-show-settings-button.optanon-toggle-display { width: 100% !important; } .cookie-banner { flex: 1 0 100%; margin-top: 1rem; margin-bottom: 1rem;} .optanon-show-settings-popup-wrapper { display: flex !important; justify-content: center !important; }', undo: false },
    ],
  },
  mixins: [updateSW, pageType],
  components: {
    LoadingScreen,
  },
  computed: {
    apiAcf() {
      return this.$store.state.acf;
    },
    checkStatusLoaded() {
      return (
        this.pageType.homePage
        && this.$store.state.postHomeIntro
        !== null
        && this.$store.state.fastFactsLoaded
      ) || (
        this.pageType.landingPage
        && this.$store.state.landingPage
      ) || (
        this.$store.state.page
      );
    },
  },
  methods: {
    checkStatusError() {
      return this.$store.state.errored;
    },
    spaPrerenderTrigger() {
      // for app builds, to let spa-prerenderer know the page is fully loaded
      if (process.env.NODE_ENV === 'production') {
        setTimeout(() => {
          document.dispatchEvent(new Event('prerender-page-loaded'));
        }, 4000); // wait 4 seconds for page to settle
      }
    },
  },
};

</script>

<style lang="scss">
@import '@/scss/type/typefaces.scss';

$Theinhardt: 'Theinhardt', 'Arial', sans-serif;

body {
  font-family: $Theinhardt;
}

// div[lazy=loading],
// img[lazy=loading] {
//   filter: blur(5px);
// }

.sw-update-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background-color: #000;
  z-index: 999999;
}
.sw-update {
  position: fixed;
  bottom: 25%;
  text-align: center;
  left: 0;
  right: 0;
  max-width: 380px;
  margin: 0 auto;
  background-color: $red;
  padding: 20px;
  color: #ffffff;
  z-index: 1000000;
  button {
    margin-left: 5px;
    padding: 10px;
  }
}

</style>
