<template>
  <div>
    <div
      :class="labelPlacement === 'hidden_label' ? 'gheader__hidden': null"
      class="gheader__label-container"
    >
      <label class="gheader__label" :for="inputId"
        >{{ label }}
        <span class="gheader__required" v-if="isRequired"> * </span>
      </label>
    </div>
    <div
      class="gheader__description"
      :id="descriptionId"
      v-if="showDescriptionAbove"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldHeader',
  props: [
    'inputId',
    'showDescriptionAbove',
    'isRequired',
    'label',
    'description',
    'descriptionId',
    'labelPlacement',
  ],
};
</script>

<style scoped lang="scss">
.gheader {
  &__hidden {
    clip: rect(1px,1px,1px,1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  &__label {
    line-height: 1.25em;
    font-size: 1em;
    font-weight: 700;
    &-container {
      margin-bottom: 16px;
    }
  }

  &__required {
    color: #790000;
  }

  &__description {
    font-weight: 400;
    margin: 8px 0;
    font-size: 12px;
  }
}
</style>
