<template>
  <fieldset class="gtime">
    <FieldHeader
      :inputId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="showDescriptionAbove"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <div class="gtime-inputs__container">
        <span>
          <NumberInput
            class="gtime-input"
            :fieldName="joinSlugs('input', ...getInputIds(hourInput))"
            fieldType="number"
            :placeholder="fieldData.placeholder"
            :fieldId="joinSlugs('input', fieldData.formId, fieldData.id, 1)"
            :handleInput="handleHourInput"
            :ariaRequired="fieldData.isRequired"
            :defaultValue="hourInput.defaultValue"
            :showLabel="true"
            :label="hourInput.customLabel || hourInput.label"
            min="0"
            :max="this.fieldData.timeFormat"
            step="1"
            size="large"
          >
          </NumberInput>
        </span>
        <span class="gtime-colon"><b>:</b></span>
        <span>
          <NumberInput
            class="gtime-input"
            :fieldName="joinSlugs('input', ...getInputIds(minuteInput))"
            fieldType="number"
            :placeholder="fieldData.placeholder"
            :fieldId="joinSlugs('input', fieldData.formId, fieldData.id, 2)"
            :handleInput="handleMinuteInput"
            :ariaRequired="fieldData.isRequired"
            :showLabel="true"
            :label="minuteInput.customLabel || minuteInput.label"
            min="0"
            max="59"
            step="1"
            :defaultValue="minuteInput.defaultValue"
            size="large"
          >
          </NumberInput>

        </span>
        <span v-if="showDropdown" class="gtime-select__container">
          <select
            class="gtime-select__input"
            :name="joinSlugs('input', ...getInputIds(inputs[2]))"
            :id="joinSlugs('input', fieldData.formId, fieldData.id, 3)"
            :aria-label="fieldData.type + inputs[2].label"
            @input="handleChange"
          >
            <option value="am" :selected="meridiemInput.defaultValue === 'AM'">AM</option>
            <option value="pm" :selected="meridiemInput.defaultValue === 'PM'">PM</option>
          </select>
        </span>
    </div>
    <FieldFooter
      :descriptionId="descriptionId"
      :showDescriptionBelow="showDescriptionBelow"
      :maxLen="fieldData.maxLength"
      :description="fieldData.description"
    >
    </FieldFooter>
  </fieldset>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
import FieldFooter from './partials/FieldFooter.vue';
import NumberInput from './partials/FieldInput.vue';

export default {
  name: 'GravityTime',
  mixins: [GravityForms],
  components: { FieldHeader, FieldFooter, NumberInput },
  data() {
    return {
      meridiem: 'am',
      hours: 0,
      minutes: 0,
    };
  },
  mounted() {
    this.meridiem = this.meridiemInput.defaultValue === 'PM' ? 'pm' : 'am';
    this.hours = this.hourInput.defaultValue;
    this.minutes = this.minuteInput.defaultValue;
    this.emit();
  },
  methods: {
    handleHourInput(event) {
      this.hours = event.target.value;
      this.emit();
    },
    handleMinuteInput(event) {
      this.minutes = event.target.value;
      this.emit();
    },
    handleChange(event) {
      this.meridiem = event.target.value;
      this.emit();
    },
    emit() {
      this.$emit('input', {
        name: this.joinSlugs('input', this.fieldData.id),
        type: this.fieldData.type,
        value: this.getFormattedValue(),
      });
    },
    getFormattedValue() {
      let suffix = '';
      if (this.showDropdown) {
        suffix = ` ${this.meridiem}`;
      }
      return `${this.hours}:${this.minutes}${suffix}`;
    },
    getInputIds(input) {
      return input.id.split('.');
    },
  },
  computed: {
    inputs() {
      return this.fieldData.inputs;
    },
    hourInput() {
      return this.inputs[0];
    },
    minuteInput() {
      return this.inputs[1];
    },
    meridiemInput() {
      return this.inputs[2];
    },
    showDropdown() {
      return this.fieldData.timeFormat === '12';
    },
  },
};
</script>

<style scoped lang="scss">
.gtime {
  padding: 4px;
  &-inputs {
    &__container {
      display: flex;
    }
  }
  &-input {
    padding-bottom: 8px;
    width: 60px;
    display: flex;
  }

  &-colon {
    margin: 0 8px;
    font-size: 18px;
  }

  &-select {
    &__input{
      text-indent: 2px;
      line-height: 1.5em;
      width: 60px;
      height: 30px;
      padding: 8px;
      margin-bottom: 4px;
    }
    &__label {
      font-weight: 400;
      margin: 8px 0;
      font-size: 12px;
    }

    &__container {
      margin-left: 16px;
      padding-bottom: 8px;
    }
  }
}
</style>
