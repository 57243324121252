<template>
  <div>
    <h2 class="gravity-forms-title">{{ gravityForms.title }}</h2>
    <div v-if="confirmationMsg && isValidSubmission === true">
      <div class="gravity-forms-message" v-html="confirmationMsg"></div>
    </div>
    <div v-else>
      <div
        class="gravity-forms-message error"
        v-if="isValidSubmission === false"
      >
        <div class="gravity-forms-message-text">
          There were {{ validationMsgCount }} error(s) in the information you
          submitted
        </div>
        <ol class="gravity-forms-message-list">
          <li
            v-for="(fieldData, index) in validationFieldsData"
            :key="index"
          >
            {{fieldData.label}} - {{ fieldData.validationMsg }}
          </li>
        </ol>
      </div>
      <form @submit.prevent="submitForm">
        <div class="gravity-forms">
          <div
            class="gravity-forms-item"
            v-for="(fieldData, index) in formFieldsData"
            :key="index"
            :class="[
              fieldData.cssClass,
              fieldData.validationMsg ? 'gravity-forms-error' : '',
            ]"
          >
            <component
              :is="getComponentName(fieldData.type)"
              :fieldData="fieldData"
              @input="handleInput"
              :validationMsgs="gravityFormsResponse['validation_messages']"
            >
            </component>
            <div
              class="gravity-forms-validation"
              aria-live="polite"
              v-if="fieldData.validationMsg"
            >
              {{ fieldData.validationMsg }}
            </div>
          </div>
        </div>
        <button class="gravity-forms-submit" type="submit">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import GravityText from '@/components/ui-components/gfComponents/GravityText.vue';
import GravitySelect from '@/components/ui-components/gfComponents/GravitySelect.vue';
import GravityChoice from '@/components/ui-components/gfComponents/GravityChoice.vue';
import GravityParagraph from '@/components/ui-components/gfComponents/GravityParagraph.vue';
import GravitySectionBreak from '@/components/ui-components/gfComponents/GravitySectionBreak.vue';
import GravityHidden from '@/components/ui-components/gfComponents/GravityHidden.vue';
import GravityName from '@/components/ui-components/gfComponents/GravityName.vue';
import GravityTel from '@/components/ui-components/gfComponents/GravityTel.vue';
import GravityDate from '@/components/ui-components/gfComponents/GravityDate.vue';
import GravityEmail from '@/components/ui-components/gfComponents/GravityEmail.vue';
import GravityAddress from '@/components/ui-components/gfComponents/GravityAddress.vue';
import GravityTime from '@/components/ui-components/gfComponents/GravityTime.vue';
import GravityConsent from '@/components/ui-components/gfComponents/GravityConsent.vue';

import { methods } from '@/mixins/gravityForms';

export default {
  name: 'GravityForms',
  props: ['pageContent', 'processUrl', 'component'],
  mixins: [methods],
  components: {
    GravityText,
    GravitySelect,
    GravityChoice,
    GravityParagraph,
    GravitySectionBreak,
    GravityHidden,
    GravityName,
    GravityTel,
    GravityDate,
    GravityEmail,
    GravityAddress,
    GravityTime,
    GravityConsent,
  },
  created() {
    this.$store.dispatch('fetchGravityFormsData', this.component.gravity_form_id);
  },
  computed: {
    gravityForms() {
      return this.$store.state.gravityFormsData || {};
    },
    formFieldsData() {
      const formData = this.gravityForms.fields || [];
      const mergedData = formData.map((datum) => {
        const { id } = datum;

        return {
          ...datum,
          validationMsg: this.validationMsg[id],
        };
      });
      return mergedData;
    },
    validationFieldsData() {
      return this.formFieldsData.filter((formData) => formData.validationMsg);
    },
    gravityFormsResponse() {
      return this.$store.state.gravityFormsResponse || {};
    },
    validationMsg() {
      return this.gravityFormsResponse.validation_messages || {};
    },
    validationMsgCount() {
      const keys = Object.keys(this.validationMsg);
      return keys.length;
    },
    confirmationMsg() {
      return this.gravityFormsResponse.confirmation_message;
    },
    isValidSubmission() {
      return this.gravityFormsResponse.is_valid;
    },
  },

  data() {
    return {
      formData: {},
    };
  },

  methods: {
    getComponentName(componentType) {
      let name = '';
      switch (componentType) {
        case 'number':
        case 'text':
        case 'website':
          name = 'GravityText';
          break;
        case 'multiselect':
        case 'select':
          name = 'GravitySelect';
          break;
        case 'radio':
        case 'checkbox':
          name = 'GravityChoice';
          break;
        case 'textarea':
          name = 'GravityParagraph';
          break;
        case 'section':
          name = 'GravitySectionBreak';
          break;
        case 'hidden':
          name = 'GravityHidden';
          break;
        case 'name':
          name = 'GravityName';
          break;
        case 'phone':
          name = 'GravityTel';
          break;
        case 'date':
          name = 'GravityDate';
          break;
        case 'email':
          name = 'GravityEmail';
          break;
        case 'address':
          name = 'GravityAddress';
          break;
        case 'time':
          name = 'GravityTime';
          break;
        case 'consent':
          name = 'GravityConsent';
          break;
        default:
          break;
      }
      return name;
    },
    handleInput(eventData) {
      const {
        name, type, value, checked,
      } = eventData;
      if (type === 'checkbox' || type === 'consent') {
        if (checked) {
          this.formData[name] = value;
        } else {
          delete this.formData[name];
        }
      } else {
        this.formData[name] = value;
      }
    },
    submitForm() {
      this.$store.dispatch('submitGravityFormsData', {
        formId: this.component.gravity_form_id,
        formData: {
          input_values: {
            ...this.formData,
          },
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.gravity-forms {
  &-title {
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 15px;
  }
  &-message {
    text-align: center;
    font-size: 20px;
    margin: 48px 0;
    padding: 16px;
    &.error {
      background: rgba(255, 223, 224, 0.25);
    }
    &-text {
      padding: 16px;
      font-size: 16px;
      font-weight: 700;
      border-top: 2px solid #790000;
      border-bottom: 2px solid #790000;
      color: #790000;
    }
    &-list {
      font-size: 14px;
      text-align: start;
      padding: 16px;
      font-weight: 700;
      color: #790000;
    }
  }

  &-item {
    margin-top: 24px;
    padding: 8px 0;
  }
  &-submit {
    font-weight: 700;
    padding: 10px 15px;
    background-color: #001e62;
    color: #fff;
    text-decoration: none;
    margin: 20px 0;
  }
  &-validation {
    font-size: 14px;
    font-weight: 700;
    padding: 16px 4px 4px 4px;
    color: #790000;
  }

  &-error {
    border-top: 1px solid #c89797;
    border-bottom: 1px solid #c89797;
    background: rgba(255, 223, 224, 0.25);
  }
}
</style>
