// common methods for GravityForms components

const joinSlugs = (...slugs) => slugs.filter((_) => _).join('_');

const methods = {
  joinSlugs,
};

export default {
  props: {
    fieldData: {
      type: Object,
      default() {
        return {};
      },
    },
    validationMsgs: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods,
  computed: {
    showDescriptionBelow() {
      return this.fieldData.description && !this.showDescriptionAbove;
    },
    showDescriptionAbove() {
      return (
        this.fieldData.description
        && this.fieldData.descriptionPlacement === 'above'
      );
    },
    showDescription() {
      return this.showDescriptionAbove || this.showDescriptionBelow;
    },
    descriptionId() {
      return this.joinSlugs('desc', this.fieldData.formId, this.fieldData.id);
    },
    ariaDescribedBy() {
      return this.showDescription ? this.descriptionId : null;
    },
    containsValidation() {
      const validationKeys = Object.keys(this.validationMsgs);

      return validationKeys.includes(this.fieldData.id);
    },
  },
};

export {
  methods,
};
