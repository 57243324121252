<template>
  <div class="gravity-select">
    <FieldHeader
      :inputId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="showDescriptionAbove"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <div
      class="gravity-select-input-container"
      :class="[{'gravity-select__single' : !isMultiSelect}, 'gravity-select__'+fieldData.size]"
    >
      <select
        :name="name"
        :id="joinSlugs('input', fieldData.formId, fieldData.id)"
        class="gravity-select-input"
        :multiple="isMultiSelect"
        @input="handleInput"
        :aria-required="fieldData.isRequired"
        :aria-describedby="ariaDescribedBy"
      >
        <option
          v-for="(choice, index) in fieldData.choices"
          :key="index"
          :value="choice.value"
          :selected="choice.isSelected"
        >
          {{ choice.text }}
        </option>
      </select>
    </div>
    <FieldFooter
      :descriptionId="descriptionId"
      :showDescriptionBelow="showDescriptionBelow"
      :description="fieldData.description"
    > </FieldFooter>
  </div>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
import FieldFooter from './partials/FieldFooter.vue';

export default {
  name: 'GravitySelect',
  components: { FieldHeader, FieldFooter },
  mixins: [
    GravityForms,
  ],
  computed: {
    isMultiSelect() {
      return this.fieldData.type === 'multiselect';
    },
    name() {
      return this.joinSlugs('input', this.fieldData.id);
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('input', {
        name: this.name,
        type: this.fieldData.type,
        value: event.target.value,
      });
    },
  },
  mounted() {
    const selectedChoice = this.fieldData.choices.find((choice) => choice.isSelected);
    this.$emit('input', {
      name: this.name,
      type: this.fieldType,
      value: selectedChoice.value,
    });
  },
};
</script>

<style scoped lang="scss">

.gravity-select {
  &__single {
    height: 46px;
    line-height: 46px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3Eicon-select-closed%3C/title%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cellipse class='_circle' stroke='%23007FA5' fill='%23fff' cx='16' cy='16' rx='15' ry='15'/%3E%3Cpath class='_chevron' stroke-width='2' stroke='%23001E62' stroke-linecap='round' d='M20 14l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 32px;
    background-position: calc(100% - 6px) center;
    background-repeat: no-repeat;
  }

  &__small {
    width: 25%;
  }
  &__medium {
    width: 50%;
  }
  &__large {
    width: 100%;
  }

  &-input {
    height: 100%;
    width: 100%;
    padding: 5px;
    background: transparent;
    font-size: inherit;
    appearance: none;

    &-container {
      border: 1px solid #d8d8d8;
      background-color: #fff;
      font-size: 20px;
      color: #001e62;
    }
  }
}
</style>
