<template>
  <div class="gravity-choice">
    <FieldHeader
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="showDescriptionAbove"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <ul
      class="gravity-choice__item-container"
      :aria-describedby="ariaDescribedBy"
    >
      <li
        class="gravity-choice__item"
        v-for="(item, index) in fieldData.choices"
        :key="index"
      >
        <input
          class="gravity-choice__input"
          :name="getChoiceName(index+1)"
          :id="joinSlugs('choice', fieldData.formId, fieldData.id, index)"
          :type="fieldData.type"
          :value="item.value"
          :checked="item.isSelected"
          :aria-required="fieldData.isRequired"
          @change="
            $emit('input', {
              checked: $event.target.checked,
              name: getChoiceName(index+1),
              type: fieldData.type,
              value: item.value,
            })
          "
        />
        <label
          class="gravity-choice__label"
          :for="joinSlugs('choice', fieldData.formId, fieldData.id, index)"
          :id="joinSlugs('label', fieldData.formId, fieldData.id, index)"
          >{{ item.value }}
        </label>
      </li>
    </ul>
    <FieldFooter
      :descriptionId="descriptionId"
      :showDescriptionBelow="showDescriptionBelow"
      :description="fieldData.description"
    >
    </FieldFooter>
  </div>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
import FieldFooter from './partials/FieldFooter.vue';

export default {
  name: 'GravityCheckbox',
  components: { FieldHeader, FieldFooter },
  mixins: [GravityForms],
  methods: {
    getChoiceName(index) {
      if (this.fieldData.type === 'checkbox') {
        return this.joinSlugs('input', this.fieldData.id, index);
      }
      return this.joinSlugs('input', this.fieldData.id);
    },
  },
  mounted() {
    const { choices } = this.fieldData;
    choices.forEach((choice, index) => {
      if (choice.isSelected) {
        this.$emit('input', {
          checked: choice.isSelected,
          name: this.getChoiceName(index + 1),
          type: this.fieldType,
          value: choice.value,
        });
      }
    });
  },
};
</script>

<style scoped lang="scss">
.gravity-choice {
  padding: 5px;

  &__item {
    margin-bottom: 8px;
    &-container {
      list-style: none;
    }
  }

  &__input {
    margin-inline-start: 2px;
    margin-inline-end: 4px;
  }

  &__heading {
    line-height: 1.25em;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__required {
    color: #790000;
  }
}
</style>
