<template>
  <div class="gravity-tel">
    <FieldHeader
      :inputId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="showDescriptionAbove"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <input
      class="gravity-tel-input"
      :class="'gravity-tel-input__'+fieldData.size"
      :name="name"
      type="tel"
      :placeholder="fieldData.placeholder"
      :id="joinSlugs('input', fieldData.formId, fieldData.id)"
      @input="handleInput"
      :aria-required="fieldData.isRequired"
      :maxlength="fieldData.maxLength"
      :min="fieldData.rangeMin"
      :max="fieldData.rangeMax"
      :aria-describedby="ariaDescribedBy"
      :value="fieldData.defaultValue"
    />
    <FieldFooter
      :descriptionId="descriptionId"
      :showDescriptionBelow="showDescriptionBelow"
      :maxLen="fieldData.maxLength"
      :description="fieldData.description"
    >
    </FieldFooter>
  </div>
</template>

<script>
// TODO: format phone number while typing (input mask)
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
import FieldFooter from './partials/FieldFooter.vue';

export default {
  name: 'GravityTel',
  mixins: [GravityForms],
  components: { FieldHeader, FieldFooter },
  mounted() {
    this.$emit('input', {
      name: this.name,
      type: this.fieldType,
      value: this.fieldData.defaultValue,
    });
  },
  methods: {
    handleInput(event) {
      this.$emit('input', {
        name: this.name,
        type: this.fieldData.type,
        value: event.target.value,
      });
    },
  },
  computed: {
    name() {
      return this.joinSlugs('input', this.fieldData.id);
    },
  },
};
</script>

<style scoped lang="scss">
.gravity-tel {
  padding: 5px;

  &-input {
    padding: 4px;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    font-size: inherit;
    color: #363636;
    line-height: 1;
    &__small {
      width: 25%;
    }
    &__medium {
      width: 50%;
    }
    &__large {
      width: 100%;
    }
  }
}
</style>
