import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import CryptoJS from 'crypto-js';

// WP server needs to allow 'Access-Control-Allow-Headers: Cache-Control'
const http = axios.create({
  headers: { 'Cache-Control': 'no-cache' },
});

Vue.use(Vuex);

const apiUrl = `${process.env.VUE_APP_URL_PROTOCOL}://${process.env.VUE_APP_API_URL}`;
const gravityFormsPublicKey = `${process.env.VUE_APP_GF_PUBLIC_KEY}`;
const gravityFormsPrivateKey = `${process.env.VUE_APP_GF_PRIVATE_KEY}`;
const uicApi = `${apiUrl}/wp-json`;
const gravityFormsApi = `${apiUrl}/gravityformsapi`;
const allPosts = '&filter[posts_per_page]=-1';

const calculateSig = (stringToSign, privateKeyParam) => {
  const hash = CryptoJS.HmacSHA1(stringToSign, privateKeyParam);
  const base64 = hash.toString(CryptoJS.enc.Base64);

  return encodeURIComponent(base64);
};

const getUrlSign = (method, route, publicKey, privateKey) => {
  const date = new Date();
  const expirationDuration = 3600 * 24;
  const currentTime = parseInt(date.getTime() / 1000, 10);
  const expireTime = currentTime + expirationDuration;
  const stringToSign = `${publicKey}:${method}:${route}:${expireTime}`;
  const sig = calculateSig(stringToSign, privateKey);

  return {
    sig,
    expireTime,
  };
};

const getSlateFormUrl = (route, method = 'GET') => {
  const { sig, expireTime } = getUrlSign(
    method,
    route,
    gravityFormsPublicKey,
    gravityFormsPrivateKey,
  );
  const url = `${gravityFormsApi}/${route}/?api_key=${gravityFormsPublicKey}&signature=${sig}&expires=${expireTime}`;

  return url;
};

const routes = {
  uicMenu: `${uicApi}/menus`,
  uicAcf: `${uicApi}/acf_options`,
  // uicMedia: `${uicApi}/media`,
  postTypeLandingPage: `${uicApi}/posts?type=landing_page${allPosts}`,
  // postTypeCampusGridPiece: `${uicApi}/posts?type=campus_grid_piece${allPosts}`,
  postTypeAcademicDates: `${uicApi}/academic-dates/upcoming`,
  postTypePage: `${uicApi}/posts?type=page${allPosts}`,
  singlePost: `${uicApi}/posts`,
  postTypeReminder: `${uicApi}/posts?type=reminder${allPosts}`,
};

// const routeCount = Object.keys(routes).length-5;

export default new Vuex.Store({
  state: {
    // uic: null,
    menus: null,
    acf: null,
    // media: null,
    landingPage: null,
    // campusGridPiece: null,
    academicDates: null,
    page: null,
    todayNews: null,
    todayEvents: null,
    connectedApi: false,
    loadedApi: 0,
    // apiCount: routeCount,
    loadedPages: 0,
    todayLoading: 0,
    errored: false,
    todayNewsErrored: false,
    todayEventsErrored: false,
    gravityFormsData: null,
    gravityFormsResponse: null,
    fastFactsLoaded: false,
    postToday: null,
    postFastFacts: null,
    postReminder: null,
    postSpotlight: null,
    postKeyMessage: null,
    postHomeIntro: null,
    // postPriorityReminder: null,
    reminder: null,
  },
  mutations: {
    // updateUIC(state, uic) {
    //   state.uic = uic;
    // },
    updateMenus(state, menus) {
      state.menus = menus;
    },
    updateAcf(state, acf) {
      state.acf = acf;
    },
    // updateMedia(state, media) {
    //   state.media = media;
    // },
    updateLandingPage(state, landingPage) {
      state.landingPage = landingPage;
    },
    // updateCampusGridPiece(state, campusGridPiece) {
    //   state.campusGridPiece = campusGridPiece;
    // },
    updateAcademicDates(state, academicDates) {
      state.academicDates = academicDates;
    },
    updatePage(state, page) {
      state.page = page;
    },
    updateNews(state, todayNews) {
      state.todayNews = todayNews;
    },
    updateEvents(state, todayEvents) {
      state.todayEvents = todayEvents;
    },
    fastFactsLoaded(state, fastFactsLoaded) {
      state.fastFactsLoaded = fastFactsLoaded;
    },
    updateGravityFormsData(state, gravityFormsData) {
      state.gravityFormsData = gravityFormsData;
    },
    updateGravityFormsResponse(state, gravityFormsResponse) {
      state.gravityFormsResponse = gravityFormsResponse;
    },
    updateReminder(state, reminder) {
      state.reminder = reminder;
    },
    updateSinglePost(state, payload) {
      switch (payload.type) {
        case 'postToday':
          state.postToday = payload.post;
          break;
        case 'postFastFacts':
          state.postFastFacts = payload.post;
          break;
        case 'postReminder':
          state.postReminder = payload.post;
          break;
        case 'postSpotlight':
          state.postSpotlight = payload.post;
          break;
        case 'postKeyMessage':
          state.postKeyMessage = payload.post;
          break;
        case 'postHomeIntro':
          state.postHomeIntro = payload.post;
          break;
        // case 'postPriorityReminder':
        //   state.postPriorityReminder = payload.post;
        //   break;
        default:
          break;
      }
    },
    resetPost(state, payload) {
      switch (payload.type) {
        case 'postToday':
          state.postToday = null;
          break;
        case 'postFastFacts':
          state.postFastFacts = null;
          break;
        case 'postReminder':
          state.postReminder = null;
          break;
        case 'postSpotlight':
          state.postSpotlight = null;
          break;
        case 'postKeyMessage':
          state.postKeyMessage = null;
          break;
        // case 'postPriorityReminder':
        //   state.postPriorityReminder = null;
        //   break;
        // case 'postHomeIntro':
        //   state.postHomeIntro = null;
        //   break;
        default:
          break;
      }
    },
  },
  actions: {
    getPost({ commit }, { id, type }) {
      http.get(`${routes.singlePost}/${id}`)
        .then((response) => {
          commit('updateSinglePost', { post: response.data, type });
        })
        .catch((error) => {
          // this.state.errored = true;
        })
        .finally(() => {
          // this.state.connectedApi = true;
        });
    },
    getApi({ commit, dispatch }) {
      // http.get(uicApi)
      //   .then((response) => {
      //     commit('updateUIC', response.data);
      //   })
      //   .catch((error) => {
      //     this.state.errored = true;
      //   })
      //   .finally(() => {
      //     this.state.connectedApi = true;
      //   });
      http.get(routes.uicMenu)
        .then((response) => {
          commit('updateMenus', response.data);
        })
        .catch((error) => {
          this.state.errored = true;
        })
        .finally(() => {
          this.state.loadedApi += 1;
        });
      http.get(routes.uicAcf)
        .then((response) => {
          commit('updateAcf', response.data);
          dispatch('getExternalApis', { data: response.data.uic_home });
        })
        .catch((error) => {
          this.state.errored = true;
        })
        .finally(() => {
          this.state.loadedApi += 1;
        });
      // http.get(routes.uicMedia)
      //   .then((response) => {
      //     commit('updateMedia', response.data);
      //   })
      //   .catch((error) => {
      //     this.state.errored = true;
      //   })
      //   .finally(() => {
      //     this.state.loadedApi += 1;
      //   });
      http.get(routes.postTypeLandingPage)
        .then((response) => {
          commit('updateLandingPage', response.data);
        })
        .catch((error) => {
          this.state.errored = true;
        })
        .finally(() => {
          this.state.loadedPages += 1;
          this.state.loadedApi += 1;
        });
      // axios.get(routes.postTypeCampusGridPiece)
      //   .then((response) => {
      //     commit('updateCampusGridPiece', response.data);
      //   })
      //   .catch((error) => {
      //     this.state.errored = true;
      //   })
      //   .finally(() => {
      //     this.state.loadedApi += 1;
      //   });
      http.get(routes.postTypeAcademicDates)
        .then((response) => {
          commit('updateAcademicDates', response.data);
        })
        .catch((error) => {
          this.state.errored = true;
        })
        .finally(() => {
          this.state.loadedApi += 1;
        });
      http.get(routes.postTypePage)
        .then((response) => {
          commit('updatePage', response.data);
        })
        .catch((error) => {
          this.state.errored = true;
        })
        .finally(() => {
          this.state.loadedPages += 1;
          this.state.loadedApi += 1;
        });
      http.get(routes.postTypeReminder)
        .then((response) => {
          commit('updateReminder', response.data);
        })
        .catch((error) => {
          this.state.errored = true;
        })
        .finally(() => {
          this.state.loadedApi += 1;
        });
    },
    fetchGravityFormsData({ commit }, formId) {
      const route = `forms/${formId}`;
      const slateUrl = getSlateFormUrl(route);
      http.get(slateUrl)
        .then((response) => {
          commit('updateGravityFormsData', response.data.response);
        })
        .catch((error) => {
        })
        .finally(() => {
          this.state.connectedApi = true;
        });
    },
    submitGravityFormsData({ commit }, payload) {
      const { formId, formData } = payload;
      const route = `forms/${formId}/submissions`;
      const slateUrl = getSlateFormUrl(route, 'POST');
      http.post(slateUrl, formData)
        .then((response) => {
          commit('updateGravityFormsResponse', response.data.response);
        })
        .catch((error) => {
        });
    },
    getExternalApis({ commit }, { data }) {
      // Today News
      axios.get(data.news_json_url)
        .then((response) => {
          commit('updateNews', response.data);
        })
        .catch((error) => {
          this.state.todayNewsErrored = true;
        })
        .finally(() => {
          this.state.todayLoading += 1;
        });
      // Today Events
      axios.get(data.events_ical_url)
        .then((response) => {
          commit('updateEvents', response.data);
        })
        .catch((error) => {
          this.state.todayEventsErrored = true;
        })
        .finally(() => {
          this.state.todayLoading += 1;
        });
    },
  },
});
