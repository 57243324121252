<template>
  <fieldset class="gaddr">
    <FieldHeader
      :inputId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="showDescriptionAbove"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <div class="gaddr__input-container">
      <TextInput
        class="gaddr__input"
        :fieldName="joinSlugs('input', ...getInputIds(streetAddr))"
        fieldType="text"
        :placeholder="streetAddr.placeholder"
        :fieldId="joinSlugs('input', fieldData.formId, fieldData.id, 1)"
        :handleInput="handleInput"
        :ariaRequired="fieldData.isRequired"
        :defaultValue="streetAddr.defaultValue"
        :showLabel="true"
        :label="streetAddr.customLabel || streetAddr.label"
        size="large"
      >
      </TextInput>

      <TextInput
        class="gaddr__input"
        :fieldName="joinSlugs('input', ...getInputIds(addr))"
        fieldType="text"
        :placeholder="addr.placeholder"
        :fieldId="joinSlugs('input', fieldData.formId, fieldData.id, 2)"
        :handleInput="handleInput"
        :ariaRequired="fieldData.isRequired"
        :defaultValue="addr.defaultValue"
        :showLabel="true"
        :label="addr.customLabel || addr.label"
        size="large"
      >
      </TextInput>
      <span>
        <TextInput
          class="gaddr__input"
          :fieldName="joinSlugs('input', ...getInputIds(city))"
          fieldType="text"
          :placeholder="city.placeholder"
          :fieldId="joinSlugs('input', fieldData.formId, fieldData.id, 3)"
          :handleInput="handleInput"
          :ariaRequired="fieldData.isRequired"
          :defaultValue="city.defaultValue"
          :showLabel="true"
          :label="city.customLabel || city.label"
          size="large"
        >
        </TextInput>
        <TextInput
          class="gaddr__input"
          :fieldName="joinSlugs('input', ...getInputIds(state))"
          fieldType="text"
          :placeholder="state.placeholder"
          :fieldId="joinSlugs('input', fieldData.formId, fieldData.id, 4)"
          :handleInput="handleInput"
          :ariaRequired="fieldData.isRequired"
          :defaultValue="state.defaultValue"
          :showLabel="true"
          :label="state.customLabel || state.label"
          size="large"
        >
        </TextInput>
      </span>
      <span>
        <TextInput
          class="gaddr__input"
          :fieldName="joinSlugs('input', ...getInputIds(zip))"
          fieldType="text"
          :placeholder="zip.placeholder"
          :fieldId="joinSlugs('input', fieldData.formId, fieldData.id, 5)"
          :handleInput="handleInput"
          :ariaRequired="fieldData.isRequired"
          :defaultValue="zip.defaultValue"
          :showLabel="true"
          :label="zip.customLabel || zip.label"
          size="large"
        >
        </TextInput>
        <TextInput
          class="gaddr__input"
          :fieldName="joinSlugs('input', ...getInputIds(country))"
          fieldType="text"
          :placeholder="country.placeholder"
          :fieldId="joinSlugs('input', fieldData.formId, fieldData.id, 6)"
          :handleInput="handleInput"
          :ariaRequired="fieldData.isRequired"
          :defaultValue="country.defaultValue"
          :showLabel="true"
          :label="country.customLabel || country.label"
          size="large"
        >
        </TextInput>
      </span>
    </div>
    <FieldFooter
      :descriptionId="descriptionId"
      :showDescriptionBelow="showDescriptionBelow"
      :maxLen="fieldData.maxLength"
      :description="fieldData.description"
    >
    </FieldFooter>
  </fieldset>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
import FieldFooter from './partials/FieldFooter.vue';
import TextInput from './partials/FieldInput.vue';

export default {
  name: 'GravityAddress',
  mixins: [GravityForms],
  components: { FieldHeader, FieldFooter, TextInput },
  props: {},
  computed: {
    inputs() {
      return this.fieldData.inputs;
    },
    streetAddr() {
      return this.inputs[0];
    },
    addr() {
      return this.inputs[1];
    },
    city() {
      return this.inputs[2];
    },
    state() {
      return this.inputs[3];
    },
    zip() {
      return this.inputs[4];
    },
    country() {
      return this.inputs[5];
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('input', {
        name: event.target.name,
        type: this.fieldData.type,
        value: event.target.value,
      });
    },
    getInputIds(input) {
      return input.id.toString().split('.');
    },
  },
  mounted() {
    this.$emit('input', {
      name: this.joinSlugs('input', ...this.getInputIds(this.streetAddr)),
      type: this.fieldData.type,
      value: this.streetAddr.defaultValue,
    });
    this.$emit('input', {
      name: this.joinSlugs('input', ...this.getInputIds(this.addr)),
      type: this.fieldData.type,
      value: this.addr.defaultValue,
    });
    this.$emit('input', {
      name: this.joinSlugs('input', ...this.getInputIds(this.city)),
      type: this.fieldData.type,
      value: this.city.defaultValue,
    });
    this.$emit('input', {
      name: this.joinSlugs('input', ...this.getInputIds(this.state)),
      type: this.fieldData.type,
      value: this.state.defaultValue,
    });
    this.$emit('input', {
      name: this.joinSlugs('input', ...this.getInputIds(this.zip)),
      type: this.fieldData.type,
      value: this.zip.defaultValue,
    });
    this.$emit('input', {
      name: this.joinSlugs('input', ...this.getInputIds(this.country)),
      type: this.fieldData.type,
      value: this.country.defaultValue,
    });
  },
};
</script>

<style scoped lang="scss">

.gaddr {
  padding: 4px;
  &__input {
    flex: 1 1 auto;
    padding-right: 16px;
    padding-bottom: 8px;
    &-container > span {

      display: flex;
    }
  }
}

</style>
