<template>
  <fieldset class="gravity-email">
    <FieldHeader
      :inputId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="showDescriptionAbove"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <div class="gravity-email-input-container">
      <EmailInput
        v-for="(input, index) in inputs"
        :key="index"
        class="gravity-email-input"
        :size="size"
        :fieldName="joinSlugs('input', ...getInputIds(input))"
        fieldType="email"
        :placeholder="input.placeholder"
        :fieldId="joinSlugs('input', fieldData.formId, fieldData.id, index + 1)"
        :handleInput="handleInput"
        :ariaRequired="fieldData.isRequired"
        :defaultValue="input.defaultValue"
        :showLabel="true"
        :label="input.customLabel || input.label"
      >
      </EmailInput>
    </div>
    <FieldFooter
      :descriptionId="descriptionId"
      :showDescriptionBelow="showDescriptionBelow"
      :maxLen="fieldData.maxLength"
      :description="fieldData.description"
    >
    </FieldFooter>
  </fieldset>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
import FieldFooter from './partials/FieldFooter.vue';
import EmailInput from './partials/FieldInput.vue';

export default {
  name: 'GravityEmail',
  mixins: [GravityForms],
  components: { FieldHeader, FieldFooter, EmailInput },
  props: {},
  mounted() {
    this.inputs.forEach((input) => {
      this.$emit('input', {
        name: this.joinSlugs('input', ...this.getInputIds(input)),
        type: this.fieldData.type,
        value: input.defaultValue,
      });
    });
  },
  computed: {
    inputs() {
      let value = [{
        id: this.fieldData.id,
        placeholder: this.fieldData.placeholder,
        defaultValue: this.fieldData.defaultValue,
      }];
      if (this.fieldData.emailConfirmEnabled) {
        value = this.fieldData.inputs;
      }
      return value;
    },
    size() {
      if (this.fieldData.emailConfirmEnabled) {
        return '';
      }
      return this.fieldData.size;
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('input', {
        name: event.target.name,
        type: this.fieldData.type,
        value: event.target.value,
      });
    },
    getInputIds(input) {
      return input.id.toString().split('.');
    },
  },
};
</script>

<style scoped lang="scss">

.gravity-email {
  padding: 4px;
  &-input {
    flex: 1 1 auto;
    padding-right: 16px;
    padding-bottom: 8px;
    &-container {
      display: flex;
    }
    &__small {
      width: 25%;
    }
    &__medium {
      width: 50%;
    }
    &__large {
      width: 100%;
    }
  }
}

</style>
