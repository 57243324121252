<template>
  <span class="text-input__container">
    <input
      class="text-input"
      :class="'text-input__'+size"
      :name="fieldName"
      :type="fieldType"
      :placeholder="placeholder"
      :id="fieldId"
      @input="handleInput"
      :aria-required="ariaRequired"
      :maxlength="maxLength"
      :min="min"
      :max="max"
      :aria-describedby="ariaDescribedBy"
      :value="defaultValue"
    />
    <label v-if="showLabel" class="text-input__label" :for="fieldId">
      {{ label }}
    </label>
  </span>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    fieldName: String,
    fieldType: String,
    placeholder: String,
    fieldId: String,
    ariaRequired: Boolean,
    maxLength: String,
    min: String,
    max: String,
    ariaDescribedBy: String,
    handleInput: {
      type: Function,
      default: () => {},
    },
    defaultValue: String,
    showLabel: {
      type: Boolean,
      default: false,
    },
    label: String,
    size: {
      type: String,
      default: 'medium',
    },
  },
};
</script>

<style scoped lang="scss">
.text-input {
  padding: 4px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  font-size: inherit;
  color: #363636;
  line-height: 1;
  width: 100%;
  &::placeholder {
    opacity: 0.6;
  }
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__label {
    font-weight: 400;
    margin: 8px 0;
    font-size: 12px;
  }
  &__small {
    width: 25%;
  }
  &__medium {
    width: 50%;
  }
  &__large {
    width: 100%;
  }
}
</style>
