<template>
  <div class="gravity-paragraph">
    <FieldHeader
      :inputId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="showDescriptionAbove"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <textarea
      class="gravity-paragraph-textarea"
      :class="'gravity-paragraph-textarea__'+fieldData.size"
      :name="name"
      :id="joinSlugs('input', fieldData.formId, fieldData.id)"
      :placeholder="fieldData.placeholder"
      rows="10"
      cols="50"
      @input="handleInput"
      :aria-required="fieldData.isRequired"
      :maxlength="fieldData.maxLength"
      :aria-describedby="ariaDescribedBy"
      :value="fieldData.defaultValue"
    >
    </textarea>
    <FieldFooter
      :descriptionId="descriptionId"
      :maxLen="fieldData.maxLength"
      :showDescriptionBelow="showDescriptionBelow"
      :description="fieldData.description"
    >
    </FieldFooter>
  </div>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
import FieldFooter from './partials/FieldFooter.vue';

export default {
  name: 'GravityParagraph',
  components: { FieldHeader, FieldFooter },
  mixins: [GravityForms],
  mounted() {
    this.$emit('input', {
      name: this.name,
      type: this.fieldData.type,
      value: this.fieldData.defaultValue,
    });
  },
  methods: {
    handleInput(event) {
      this.$emit('input', {
        name: this.name,
        type: this.fieldData.type,
        value: event.target.value,
      });
    },
  },
  computed: {
    name() {
      return this.joinSlugs('input', this.fieldData.id);
    },
  },
};
</script>

<style scoped lang="scss">
.gravity-paragraph {
  padding: 5px;

  &-textarea {
    font-size: inherit;
    font-family: inherit;
    letter-spacing: normal;
    padding: 8px;
    line-height: 1.5;
    resize: none;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    color: #363636;
    width: 100%;
    &__small {
      height: 80px;
    }
    &__medium {
      height: 160px;
    }
    &__large {
      height: 320px;
    }
  }

}
</style>
