<template>
  <header :class="{ 'interior-page-header': !pageType.homePage && !pageType.landingPage }"
  class="header header--lightgray"
  ref="header"
  v-if="this.apiAcf && this.pageType">
    {{ initializeHeroPost }}
    <div
      class="header-background-image-container"
      v-if="
        (pageType.homePage && postHero && (postHero.acf.header_type == 'Image'))
        || pageType.landingPage
      "
    >
      <div class="header-background-image"
      :style="{ backgroundImage: `url(${postHero.acf.hero_image.url}` }"
      :key="postHero.acf.hero_image.url"
      aria-hidden="true">
      </div>
    </div>

    <Navigation :pageType="pageType" ref="navigation"/>

    <PriorityReminderAlert v-if="pageType.homePage"/>
    <HeaderHero v-if="postHero"
    :hero="postHero"
    :availableNews="availableNews"
    :pageType="pageType"
    ref="headerHero">
      Content
    </HeaderHero>

  </header>

</template>

<script>

// Mixins
import pageType from '@/mixins/pageType';
import pageContent from '@/mixins/pageContent';
import todaysDate from '@/mixins/todaysDate';

// Components
import Navigation from '@/components/ui-components/Navigation.vue';
import HeaderHero from '@/components/ui-components/HeaderHero.vue'; // not lazy-loaded due to $refs dependancies
import PriorityReminderAlert from '@/components/ui-components/PriorityReminderAlert.vue';

export default {
  name: 'Header',
  components: {
    Navigation,
    HeaderHero,
    PriorityReminderAlert,
  },
  mixins: [
    pageType,
    pageContent,
    todaysDate,
  ],
  data() {
    return {
      meta_description: '',
      availableNews: false,
      postHero: null,
    };
  },
  props: ['apiAcf'],
  computed: {
    getHomeIntro() {
      return this.$store.state.postHomeIntro;
    },
    initializeHeroPost() {
      if (this.pageType.homePage) {
        let assignedHeroID = this.apiAcf.uic_home.default_intro.ID;
        const assignedNews = this.apiAcf.uic_home.news_intros;
        // Check if any assigned news available
        if (Object.keys(assignedNews).length > 0) {
          // Organize news assignments by activation (start date, hour, minute).
          assignedNews.sort((a, b) => a.start_date > b.start_date
            || a.start_hour > b.start_hour
            || a.start_minute > b.start_minute);
          // Loop through all assigned news
          for (let i = assignedNews.length - 1; i >= 0; i -= 1) {
            // Format each assigned start date
            const startDate = new Date(`${assignedNews[i].start_date} ${assignedNews[i].start_hour}:${assignedNews[i].start_minute}:00`);
            // Format each assigned expire date
            const expireDate = new Date(`${assignedNews[i].expire_date} ${assignedNews[i].expire_hour}:${assignedNews[i].expire_minute}:00`);
            /* Compare activation date and expiration date to todays date and time.
            Remove inactive assigned news. */
            if (startDate > this.todaysDate || expireDate < this.todaysDate) {
              assignedNews.splice(i, 1);
            }
          }
          /* If any news assignments remaining,
          and if multiple available,
          over-ride default assignedHeroID with the latest one (top of list) */
          if (Object.keys(assignedNews).length > 0) {
            assignedHeroID = assignedNews[0].news_intro.ID;
            this.availableNews = true;
          }
        }
        this.$store.dispatch('getPost', { id: assignedHeroID, type: 'postHomeIntro' });
      } else if (this.pageType.landingPage) {
        this.postHero = this.pageContent;
      }
    },
  },
  methods: {
    updateHeadMeta() {
      this.meta_description = this.apiAcf.uic_home.calendar_button_text;
      this.$emit('updateHead');
    },
  },
  updated() {
    if (this.postHero) {
      const breakpoint = this.$refs.header.clientHeight;
      let elementsChangingOnScroll = [this.$refs.navigation.$refs.navigationPrimary,
        this.$refs.headerHero.$refs.headerContent];
      if (this.pageType.innerPage === true) {
        elementsChangingOnScroll = elementsChangingOnScroll.slice(0, 1);
      }
      let state = 'before';

      if (window.requestAnimationFrame === undefined) {
        window.requestAnimationFrame = (fn) => {
          fn();
        };
      }

      const applyAfterState = function expression() {
        state = 'after';
        window.requestAnimationFrame(() => {
          elementsChangingOnScroll.forEach((el) => el.classList.add('scrolled-after'));
          elementsChangingOnScroll.forEach((el) => el.classList.remove('scrolled-before', 'scrolled-before-active'));
          window.requestAnimationFrame(() => {
            if (state === 'after') elementsChangingOnScroll.forEach((el) => el.classList.add('scrolled-after-active'));
          });
        });
      };

      const applyBeforeState = function expression() {
        state = 'before';
        window.requestAnimationFrame(() => {
          elementsChangingOnScroll.forEach((el) => el.classList.add('scrolled-before'));
          elementsChangingOnScroll.forEach((el) => el.classList.remove('scrolled-after', 'scrolled-after-active'));
          window.requestAnimationFrame(() => {
            if (state === 'before') elementsChangingOnScroll.forEach((el) => el.classList.add('scrolled-before-active'));
          });
        });
      };

      const applyBaseState = function expression() {
        state = 'before';
        window.requestAnimationFrame(() => {
          elementsChangingOnScroll.forEach((el) => el.classList.remove('scrolled-after', 'scrolled-after-active', 'scrolled-before', 'scrolled-before-active'));
        });
      };

      window.addEventListener('scroll', () => {
        const scroll = window.scrollY;
        if (scroll < 50) applyBaseState();
        else if (scroll > breakpoint && state === 'before') applyAfterState();
        else if (scroll < breakpoint && state === 'after') applyBeforeState();
      });
    }
  },
  watch: {
    getHomeIntro(newVal) {
      if (newVal !== null && this.pageType.homePage) {
        this.postHero = newVal;
      }
    },
    'pageType.homePage': {
      handler(newVal) {
        if (newVal === true) {
          this.postHero = this.getHomeIntro;
        }
      },
    },
    'pageType.landingPage': {
      handler(newVal) {
        if (newVal === true) {
          this.postHero = this.pageContent;
        }
      },
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.header {
  position: relative;
  width: 100%;
  background-color: black;
}

.header-background-image-container {
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  position: absolute;
  right: 0px;
  top: 0px;
  .header-background-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -5px;
    filter: blur(5px);
    left: -5px;
    position: absolute;
    right: -5px;
    top: -5px;
  }
}

// Inner Page

.interior-page-header {
  .header-background-image {
    display: none;
  }
}

</style>
