<template>
  <div class="gravity-text">
    <FieldHeader
      :inputId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="showDescriptionAbove"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <TextInput
      class="gravity-text__input"
      :fieldName="name"
      :fieldType="fieldType"
      :placeholder="fieldData.placeholder"
      :fieldId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :handleInput="handleInput"
      :ariaRequired="fieldData.isRequired"
      :maxLength="fieldData.maxLength"
      :min="fieldData.rangeMin"
      :max="fieldData.rangeMax"
      :ariaDescribedBy="ariaDescribedBy"
      :defaultValue="fieldData.defaultValue"
      :size="fieldData.size"
    >
    </TextInput>
    <FieldFooter
      :descriptionId="descriptionId"
      :showDescriptionBelow="showDescriptionBelow"
      :maxLen="fieldData.maxLength"
      :charCount="charCount"
      :description="fieldData.description"
    >
    </FieldFooter>
  </div>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
import FieldFooter from './partials/FieldFooter.vue';
import TextInput from './partials/FieldInput.vue';

export default {
  name: 'GravityText',
  mixins: [GravityForms],
  components: { FieldHeader, FieldFooter, TextInput },
  data() {
    return {
      charCount: 0,
    };
  },
  mounted() {
    this.$emit('input', {
      name: this.name,
      type: this.fieldType,
      value: this.fieldData.defaultValue,
    });
  },

  methods: {
    handleInput(event) {
      this.$emit('input', {
        name: this.name,
        type: this.fieldType,
        value: event.target.value,
      });
      this.charCount = event.target.value.length;
    },
  },
  computed: {
    fieldType() {
      let fieldType = this.fieldData.type;
      if (this.fieldData.enablePasswordInput) {
        fieldType = 'password';
      } else if (this.fieldData.type === 'website') {
        fieldType = 'url';
      }
      return fieldType;
    },
    name() {
      return this.joinSlugs('input', this.fieldData.id);
    },
  },
};
</script>

<style scoped lang="scss">
.gravity-text {
  padding: 4px;
}
</style>
