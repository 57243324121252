<template>
  <div class="gravity-section-break__wrapper">
    <h2 class="gravity-section-break__label">
      {{ fieldData.label }}
    </h2>
    <div class="gravity-section-break__desc">
      {{ fieldData.description }}
    </div>
  </div>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';

export default {
  name: 'GravitySectionBreak',
  mixins: [GravityForms],
};
</script>

<style scoped lang="scss">
.gravity-section-break {
  &__wrapper {
    padding: 24px 16px 12px 0;
    border-bottom: 1px solid #ccc;
  }
  &__desc {
    margin: 8px 0;
    font-size: 0.8em;
  }
  &__label {
    font-weight: 700;
    font-size: 1.25em;
  }
}
</style>
