<template>
  <fieldset class="gname">
    <FieldHeader
      :inputId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="showDescriptionAbove"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <div class="gname-inputs__container">
      <span
        v-for="(input, index) in inputs"
        :key="index"
        :id="
          joinSlugs('input', fieldData.formId, fieldData.id, index + 1, 'container')
        "
        class="gname-input__container"
      >
        <span v-if="!input.choices">
          <TextInput
            v-if="!input.isHidden"
            class="gname-input"
            :fieldName="joinSlugs('input', ...getInputIds(input))"
            fieldType="text"
            :placeholder="fieldData.placeholder"
            :fieldId="joinSlugs('input', fieldData.formId, fieldData.id, index + 1)"
            :handleInput="handleInput"
            :ariaRequired="fieldData.isRequired"
            :defaultValue="input.defaultValue"
            size="large"
            :showLabel="true"
            :label="input.customLabel || input.label"
          >
          </TextInput>
        </span>
        <span v-if="input.choices" class="gname-select__container">
          <select
            class="gname-select__input"
            :name="joinSlugs('input', ...getInputIds(input))"
            :id="joinSlugs('input', fieldData.formId, fieldData.id, index + 1)"
            :aria-label="fieldData.type + input.label"
            @input="handleInput"
          >
            <option value="">--</option>
            <option
              v-for="(choice, index) in input.choices"
              :key="index"
              :value="choice.value"
              :selected="choice.value === input.defaultValue"
            >{{choice.text}}</option>
          </select>
          <label
            v-if="!input.isHidden"
            class="gname-select__label"
            :for="joinSlugs('input', fieldData.formId, fieldData.id, index + 1)"
            >{{ input.label }}
          </label>
        </span>
      </span>
    </div>
    <FieldFooter
      :descriptionId="descriptionId"
      :showDescriptionBelow="showDescriptionBelow"
      :maxLen="fieldData.maxLength"
      :description="fieldData.description"
    >
    </FieldFooter>
  </fieldset>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
import FieldFooter from './partials/FieldFooter.vue';
import TextInput from './partials/FieldInput.vue';

export default {
  name: 'GravityName',
  mixins: [GravityForms],
  components: { FieldHeader, FieldFooter, TextInput },
  mounted() {
    this.inputs.forEach((input) => {
      this.$emit('input', {
        name: this.joinSlugs('input', ...this.getInputIds(input)),
        type: this.fieldData.type,
        value: input.defaultValue,
      });
    });
  },
  methods: {
    handleInput(event) {
      this.$emit('input', {
        name: event.target.name,
        type: this.fieldData.type,
        value: event.target.value,
      });
    },
    getInputIds(input) {
      return input.id.split('.');
    },
  },
  computed: {
    inputs() {
      return this.fieldData.inputs;
    },
  },
};
</script>

<style scoped lang="scss">
.gname {
  padding: 4px;
  &-inputs {
    &__container {
      display: flex;
    }
  }
  &-input {
    padding-right: 16px;
    padding-bottom: 8px;
  }

  &-select {
    &__input{
      text-indent: 2px;
      line-height: 1.5em;
      height: 30px;
      padding: 8px;
      margin-bottom: 4px;
    }
    &__label {
      font-weight: 400;
      margin: 8px 0;
      font-size: 12px;
    }

    &__container {
      padding-right: 16px;
      padding-bottom: 8px;
    }
  }
}
</style>
