<template>

<main>
  <h1 id="home-page-title">University of Illinois Chicago</h1>
  <fast-facts/>

  <template v-if="checkStatusLoaded">
      <div ref="callToAction" class="scroll-component">
        <call-to-action v-if="showCallToAction" />
      </div>
      <div class="ms-grid zero-margin extra-wide-grid full">
        <div ref="spotlightBlock" class="scroll-component">
          <spotlight-block v-if="showSpotlightBlock"></spotlight-block>
        </div>
        <div ref="todayBlock" class="scroll-component">
          <today-block v-if="showTodayBlock"></today-block>
        </div>
        <div ref="keyMessage" class="scroll-component">
          <key-message2020-block v-if="showKeyMessage"></key-message2020-block>
        </div>
      </div>
      <div ref="campusReminders" class="scroll-component">
        <campus-reminders v-if="showCampusReminders" />
      </div>
    </template>


</main>

</template>

<script>

const FastFacts = () => import('@/components/ui-components/FastFacts.vue')
const CallToAction = () => import('@/components/ui-components/CallToAction.vue')
const CampusReminders = () => import('@/components/ui-components/CampusReminders.vue')
const SpotlightBlock = () => import('@/components/ui-components/SpotlightBlock.vue')
const KeyMessage2020Block = () => import('@/components/ui-components/KeyMessage2020Block.vue')
const TodayBlock = () => import('@/components/ui-components/TodayBlock.vue')

export default {
  name: 'HomeBody',
  components: {
    FastFacts,
    CallToAction,
    CampusReminders,
    SpotlightBlock,
    KeyMessage2020Block,
    TodayBlock,
  },
  props: ['checkStatusLoaded'],
  data() {
    return {
      showCallToAction: false,
      showSpotlightBlock: false,
      showTodayBlock: false,
      showKeyMessage: false,
      showCampusReminders: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      const callToActionRef = this.$refs["callToAction"]
      const spotlightBlockRef = this.$refs["spotlightBlock"]
      const todayBlockRef = this.$refs["todayBlock"]
      const keyMessageRef = this.$refs["keyMessage"]
      const campusRemindersRef = this.$refs["campusReminders"]


      const innerHeight = window.innerHeight;


      if (callToActionRef) {
        var marginTopCallToAction = callToActionRef.getBoundingClientRect().top;

        if (marginTopCallToAction - innerHeight < 0) {
          this.showCallToAction = true;
        }
      }
      if (spotlightBlockRef) {
        var marginTopSpotlightBlock = spotlightBlockRef.getBoundingClientRect().top;

        if (marginTopSpotlightBlock - innerHeight < 0) {
          this.showSpotlightBlock = true;
        }
      }
      if (todayBlockRef) {
        var marginTopTodayBlock = todayBlockRef.getBoundingClientRect().top;

        if (marginTopTodayBlock - innerHeight < 0) {
          this.showTodayBlock = true;
        }
      }
      if (keyMessageRef) {
        var marginTopKeyMessage = keyMessageRef.getBoundingClientRect().top;

        if (marginTopKeyMessage - innerHeight < 0) {
          this.showKeyMessage = true;
        }
      }
      if (campusRemindersRef) {
        var marginTopCampusReminders = campusRemindersRef.getBoundingClientRect().top;

        if (marginTopCampusReminders - innerHeight < 0) {
          this.showCampusReminders = true;
        }
      }
    }
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.scroll-component{
  min-height: 50px;
}
</style>
