<template>
  <div class="gravity-date">
    <FieldHeader
      :inputId="joinSlugs('input', fieldData.formId, fieldData.id)"
      :isRequired="fieldData.isRequired"
      :label="fieldData.label"
      :labelPlacement="fieldData.labelPlacement"
      :showDescriptionAbove="showDescriptionAbove"
      :description="fieldData.description"
      :descriptionId="descriptionId"
    >
    </FieldHeader>
    <input
      class="gravity-date__input"
      :name="name"
      :type="fieldData.type"
      :value="fieldData.defaultValue"
      :placeholder="fieldData.placeholder"
      :id="joinSlugs('input', fieldData.formId, fieldData.id)"
      @input="handleInput"
      :aria-required="fieldData.isRequired"
      :maxlength="fieldData.maxLength"
      :aria-describedby="ariaDescribedBy"
    />
    <FieldFooter
      :descriptionId="descriptionId"
      :showDescriptionBelow="showDescriptionBelow"
      :maxLen="fieldData.maxLength"
      :description="fieldData.description"
    >
    </FieldFooter>
  </div>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import FieldHeader from './partials/FieldHeader.vue';
import FieldFooter from './partials/FieldFooter.vue';

export default {
  name: 'GravityDate',
  mixins: [GravityForms],
  components: { FieldHeader, FieldFooter },
  methods: {
    handleInput(event) {
      this.$emit('input', {
        name: this.name,
        type: this.fieldData.type,
        value: event.target.value,
      });
    },
  },
  computed: {
    name() {
      return this.joinSlugs('input', this.fieldData.id);
    },
  },
  mounted() {
    this.$emit('input', {
      name: this.name,
      type: this.fieldType,
      value: this.fieldData.defaultValue,
    });
  },
};
</script>

<style scoped lang="scss">
.gravity-date {
  padding: 5px;

  &__input {
    padding: 4px;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    font-size: inherit;
    color: #363636;
    line-height: 1;
    width: 50%;
  }
}
</style>
