<template>
  <HiddenInput
    :fieldName="joinSlugs('input', fieldData.id)"
    :fieldId="joinSlugs('input', fieldData.formId, fieldData.id)"
    fieldType="hidden"
    class="gravity-hidden"
    aria-invalid="false"
    :defaultValue="fieldData.defaultValue"
  >
  </HiddenInput>
</template>

<script>
import GravityForms from '@/mixins/gravityForms';
import HiddenInput from './partials/FieldInput.vue';

export default {
  name: 'GravityHidden',
  mixins: [GravityForms],
  components: { HiddenInput },
  mounted() {
    this.$emit('input', {
      name: this.name,
      type: this.fieldType,
      value: this.fieldData.defaultValue,
    });
  },
  computed: {
    name() {
      return this.joinSlugs('input', this.fieldData.id);
    },
  },
};
</script>

<style scoped lang="scss">
.gravity-hidden {
  display: none !important;
}
</style>
